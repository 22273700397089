import React, { useState } from "react";
import referenceSheetImage from "./DSAT_ref_sheet.png"; // Import the image
import "./ReferenceSheetButton.css"; // Import CSS for styling

const ReferenceSheetButton = () => {
    const [isSheetVisible, setIsSheetVisible] = useState(false);

    const toggleSheetVisibility = () => setIsSheetVisible(!isSheetVisible);

    const handleDrag = (e) => {
        e.dataTransfer.setData("text", e.target.id);
    };

    const handleDrop = (e) => {
        const data = e.dataTransfer.getData("text");
        const element = document.getElementById(data);
        element.style.left = e.pageX + "px";
        element.style.top = e.pageY + "px";
    };

    const handleDragOver = (e) => e.preventDefault();

    return (
        <div className="hide-on-mobile">
            <button onClick={toggleSheetVisibility} className={"header-button"}>
                Reference Sheet
            </button>
            {isSheetVisible && (
                <div
                    id="draggable-element"
                    draggable="true"
                    onDragStart={handleDrag}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    className="reference-sheet"
                >
                    <img src={referenceSheetImage} alt="Reference Sheet" />
                    <button onClick={toggleSheetVisibility}>Hide</button>
                </div>
            )}
        </div>
    );
};

export default ReferenceSheetButton;
