// src/components/PromptDisplay.js
import React, { useEffect } from "react"; // Removed useState since it's no longer used here
import MathJaxWrapper from "../../utils/MathJaxWrapper";
import "./PromptDisplay.css"; // Ensure you import the CSS file
import { preprocessText } from "../../utils/TextProcessing";
import GetImageSrc from "../../utils/GetImageSrc";

const MarkForReview = ({ isMarked, onToggle }) => {
    // Use isMarked prop
    return (
        <div className="mark-for-review" onClick={onToggle}>
            <span className={`flag-icon ${isMarked ? "marked" : ""}`}>🚩</span>
            <span>Mark for Review</span>
        </div>
    );
};

const PromptDisplay = ({
    prompt,
    currentQuestionIndex,
    imageData,
    markForReview,
    onMarkForReview,
    strikeThroughMode,
    setStrikeThroughMode,
    viewAnswerMode,
    setViewAnswerMode,
    tutorMode,
    stepStatus,
}) => {
    const processedText = preprocessText(prompt);
    const showImage = imageData !== "none";

    const handleToggleStrikeThroughMode = () => {
        setStrikeThroughMode(!strikeThroughMode);
        setViewAnswerMode(false);
    };

    const handleViewAnswer = () => {
        setViewAnswerMode(!viewAnswerMode);
        setStrikeThroughMode(false);
    };

    useEffect(() => {
        setStrikeThroughMode(false);
        setViewAnswerMode(false);
    }, [currentQuestionIndex]);

    const containsLatexAlign = processedText.includes("\\begin{align}");

    return (
        <MathJaxWrapper>
            <div className="question-number-bar">
                <div className="prompt-button-container">
                    <div className="question-number">
                        {currentQuestionIndex}
                    </div>
                    <MarkForReview
                        isMarked={markForReview || false}
                        onToggle={onMarkForReview}
                    />
                </div>
                <div className="prompt-button-container">
                    {(tutorMode || stepStatus === "revising") && (
                        <button
                            className="view-answer-button header-button"
                            onClick={handleViewAnswer}
                        >
                            <span>View Answers</span>
                        </button>
                    )}
                    <button
                        className="abc-strike-toggle header-button"
                        onClick={handleToggleStrikeThroughMode}
                    >
                        <span style={{ textDecoration: "line-through" }}>
                            ABC
                        </span>
                    </button>
                </div>
            </div>
            {showImage && (
                <div className="image-container">
                    <img src={GetImageSrc(imageData)} alt="Question Image" />
                </div>
            )}
            {prompt !== "none" && (
                <div
                    className={`prompt-section ${containsLatexAlign ? "centered-content" : ""}`}
                    dangerouslySetInnerHTML={{ __html: processedText }}
                />
            )}
        </MathJaxWrapper>
    );
};

export default PromptDisplay;
