// src/components/NavigationConsole.js
import React, { useState, useEffect } from "react";
import "./Navigation.css"; // Ensure this path is correct

const NavigationConsole = ({
    problemMode,
    navBarIndices,
    currentQuestion,
    onSelectQuestion,
    isVisible,
    toggleVisibility,
    answeredQuestions,
    markForReview,
    onReviewPageToggle, //  = {},
    inModal = false,
}) => {
    const [columns, setColumns] = useState(10);
    const wrapperClass = inModal
        ? "navigation-console-modal"
        : "navigation-console-wrapper";

    useEffect(() => {
        const updateColumns = () => {
            const screenWidth = window.innerWidth;
            let columnCount = Math.min(navBarIndices.length, 10);

            if (screenWidth < 600) {
                columnCount = Math.min(columnCount, 6);
            } else if (screenWidth < 900) {
                columnCount = Math.min(columnCount, 10);
            }

            setColumns(columnCount);
        };

        updateColumns();
        window.addEventListener("resize", updateColumns);

        return () => window.removeEventListener("resize", updateColumns);
    }, [navBarIndices.length]);

    return (
        <div className={wrapperClass}>
            {" "}
            {/* Use wrapperClass here to apply the correct CSS class */}
            {!inModal && (
                <button
                    className="navigation-console-toggle"
                    onClick={toggleVisibility}
                >
                    {problemMode === "revisions"
                        ? `Question ${navBarIndices[currentQuestion]}`
                        : `Question ${currentQuestion + 1} of ${navBarIndices.length}`}
                </button>
            )}
            {isVisible && (
                <div className="navigation-console-content">
                    <div
                        className="navigation-console"
                        style={{
                            gridTemplateColumns: `repeat(${columns}, minmax(40px, 1fr))`,
                        }}
                    >
                        {navBarIndices.map((value, index) => (
                            <button
                                key={index}
                                className={`
                                       nav-button
                                       ${index === currentQuestion ? "current" : ""}
                                       ${answeredQuestions.has(String(index)) ? "answered" : ""}
                                       ${markForReview.has(String(index)) ? "marked-for-review" : ""}
                                   `}
                                onClick={() => onSelectQuestion(index)}
                            >
                                {value}
                            </button>
                        ))}
                    </div>
                    {!inModal && (
                        <div className="review-button-container">
                            <button
                                className="review-page-button"
                                onClick={onReviewPageToggle}
                            >
                                Review and Submit
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NavigationConsole;
