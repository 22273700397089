import React, { useEffect, useState } from "react";
import { TestInfoContainer } from "../settings/settings_pages/account_components/TestInfo";
import { PersonalInfoContainer } from "../settings/settings_pages/account_components/PersonalInfo";
import { TutorInfoContainer } from "../settings/settings_pages/account_components/TutorInfo";

import GenericAsync from "../backend/GenericAsync";
import ConfirmModal from "../popups/ConfirmModal";

const UpdateUserComponent = ({ action, userInfo, setUserInfo }) => {
    const [saveAbility, setSaveAbility] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [modalActive, setModalActive] = useState(false);
    const [modalStage, setModalStage] = useState(1);
    const [saveError, setSaveError] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleInfoSubmit = () => {
        setModalActive(false);
        setModalStage(1);
        setSaveError(false);
        setUserInfo({});
    };

    useEffect(() => {
        if (Object.keys(userInfo).length === 0) {
            setSaveAbility(false);
        } else {
            setSaveAbility(true);
        }
    }, [userInfo]);

    const saveNewUser = async () => {
        setModalActive(true);
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/update-user-info`,
                dataToSend: {
                    userEmail: userInfo.userEmail,
                    settings: {
                        ...userInfo,
                        createNewUser: action === "add_user",
                    },
                },
            });

            setModalStage(2);
            if (!response.success) {
                setSaveError(true);
            }
        } catch (error) {
            setModalStage(2);
            setSaveError(true);
            console.error("Error saving user:", error); // Add error logging
        }
    };

    const renderSubscriptionType = () => {
        return (
            <div className={"settings-text-container"}>
                <div className="settings-text-column">
                    <div className="settings-column-title">
                        Susbcription Type:
                    </div>
                    <select
                        name="subscription"
                        value={userInfo.subscription ?? "basic"}
                        onChange={handleChange}
                        className="settings-select-style"
                    >
                        <option value={"basic"}>Basic</option>
                        <option value={"partner"}>Partner</option>
                        <option value={"tutor"}>Tutor</option>
                    </select>
                </div>
            </div>
        );
    };

    const returnModalText = () => {
        if (modalStage === 1) {
            return "Saving...";
        } else {
            if (!saveError) {
                return "User info submission successful!";
            } else {
                return "User info submission failed... check browser developer tools.";
            }
        }
    };

    return (
        <>
            <div>
                <PersonalInfoContainer
                    settings={userInfo}
                    setSettings={setUserInfo}
                    fixedEmail={false}
                    addNewUser={action === "add_user"}
                />
                <TestInfoContainer
                    settings={userInfo}
                    setSettings={setUserInfo}
                    addNewUser={action === "add_user"}
                />
                <TutorInfoContainer
                    settings={userInfo}
                    setSettings={setUserInfo}
                />
                {renderSubscriptionType()}
            </div>
            <div
                style={{
                    marginTop: "30px",
                    marginLeft: "30px",
                    marginBottom: "60px",
                }}
            >
                <button
                    className={`orange-button ${saveAbility ? "" : "disabled"}`}
                    onClick={saveAbility ? saveNewUser : null}
                    disabled={!saveAbility}
                    style={{ margin: "0px" }}
                >
                    Save Settings
                </button>
            </div>
            <ConfirmModal
                isOpen={modalActive}
                onConfirm={modalStage === 2 ? handleInfoSubmit : null}
                message={
                    <div
                        style={{
                            textAlign: "center",
                            lineHeight: "36px",
                            fontSize: "18px",
                        }}
                    >
                        {returnModalText()}
                    </div>
                }
                showActions={modalStage === 2}
                confirmMessage={<div style={{ fontSize: "18px" }}>Go Back</div>}
            />
        </>
    );
};

export default UpdateUserComponent;
