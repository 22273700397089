// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reference-sheet {
    position: absolute;
    top: 150px; /* Starting position */
    right: 50px; /* Starting position */
    z-index: 10; /* Make sure it's above other elements */
}

.reference-sheet img {
    max-width: 1000px;
    height: auto; /* Adjust size as needed */
    display: block;
}

/* Style the "Hide" button inside the reference sheet if needed */
.reference-sheet button {
    display: block;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/problem_console/header/ReferenceSheetButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU,EAAE,sBAAsB;IAClC,WAAW,EAAE,sBAAsB;IACnC,WAAW,EAAE,wCAAwC;AACzD;;AAEA;IACI,iBAAiB;IACjB,YAAY,EAAE,0BAA0B;IACxC,cAAc;AAClB;;AAEA,iEAAiE;AACjE;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".reference-sheet {\n    position: absolute;\n    top: 150px; /* Starting position */\n    right: 50px; /* Starting position */\n    z-index: 10; /* Make sure it's above other elements */\n}\n\n.reference-sheet img {\n    max-width: 1000px;\n    height: auto; /* Adjust size as needed */\n    display: block;\n}\n\n/* Style the \"Hide\" button inside the reference sheet if needed */\n.reference-sheet button {\n    display: block;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
