const GetImageSrc = (data) => {
    if (data) {
        // Check for SVG format
        if (data.startsWith("PHN") || data.startsWith("PD94")) {
            return `data:image/svg+xml;base64,${data}`;
        } else {
            // Assume PNG format otherwise
            return `data:image/png;base64,${data}`;
        }
    }
    return null;
};

export default GetImageSrc;
