import Navbar from "../common/Navbar";
import React, { useState } from "react";
import AccountInfo from "./settings_pages/AccountInfo";
import SubscriptionInfo from "./settings_pages/SubscriptionInfo";
import Footer from "../common/Footer";
import { useSettings } from "../common/SettingsContext";

const Settings = () => {
    const [selectedSection, setSelectedSection] = useState("account");
    const [isBlurred, setIsBlurred] = useState(false);
    const { settings, subscriptionBannerShown } = useSettings();

    const renderContent = () => {
        switch (selectedSection) {
            case "account":
                return <AccountInfo />;
            case "subscription":
                return <SubscriptionInfo />;
        }
    };

    return (
        <div className="desktop-homepage">
            <Navbar setIsBlurred={setIsBlurred} />
            <div
                className={`main-content ${isBlurred ? "blur-effect" : ""}`}
                style={
                    subscriptionBannerShown
                        ? { paddingTop: "140px" }
                        : { paddingTop: "100px" }
                }
            >
                <div className="main-body-text">
                    <div className="page-header">Settings</div>
                    <div className="settings-container">
                        <div className="settings-sidebar">
                            <div
                                className={`settings-sidebar-option ${selectedSection === "account" ? "selected" : ""}`}
                                onClick={() => setSelectedSection("account")}
                            >
                                ACCOUNT
                            </div>
                            <div
                                className={`settings-sidebar-option ${selectedSection === "subscription" ? "selected" : ""}`}
                                onClick={() =>
                                    setSelectedSection("subscription")
                                }
                            >
                                SUBSCRIPTION
                            </div>
                        </div>
                        {settings ? (
                            <div className="settings-main-text">
                                {renderContent()}
                            </div>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Settings;
