import React, { useCallback, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoginContext } from "../auth/LoginContext";
import { trackStripeConversion } from "../meta/MetaPixel";
import GenericAsync from "../backend/GenericAsync";

export const CheckoutForm = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const stripePromise = loadStripe(
        "pk_live_51ORPyNBMdMmJILuHwMGs5gwyW900qt9u013D47CrPxlQV99IvpJwbSJIm0S6NskaZA5OHLlGmCFKN7JT2fx1ypQE00gb4FKhuo",
    );
    const { user } = useAuth0();

    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        return fetch(`${backendUrl}/api/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user.email }),
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, [user.email]);

    const options = { fetchClientSecret };
    return (
        <div className="stripe-checkout-wrapper">
            <div className="stripe-checkout-form">
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        </div>
    );
};

export function CheckoutReturn() {
    const [processed, setProcessed] = useState(false);
    const { setLoginAbility } = useLoginContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const checkoutComplete = queryParams.get("checkout_complete");
        const sessionId = queryParams.get("session_id");

        if (checkoutComplete === "true" && sessionId && !processed) {
            const processCheckoutReturn = async () => {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                try {
                    const response = await fetch(
                        `${backendUrl}/api/session-status?session_id=${sessionId}`,
                    );
                    const data = await response.json();

                    if (data.status === "complete") {
                        const eventId = trackStripeConversion(
                            data.customer_email,
                        );
                        await GenericAsync({
                            backendUrl: `${backendUrl}/api/track-stripe-conversion`,
                            dataToSend: {
                                userEmail: data.customer_email,
                                eventId: eventId,
                                sessionId: sessionId,
                            },
                        });
                        setLoginAbility(true);
                    }
                } catch (error) {
                    console.error("Error processing checkout return:", error);
                }

                setProcessed(true);
                // Remove the query parameters
                navigate("/", { replace: true });
            };

            processCheckoutReturn();
        }
    }, [location, processed, setLoginAbility, navigate]);

    return null;
}
