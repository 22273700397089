// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/QuestionDisplay.css */
.question-section {
    padding: 10px;
    text-align: left;
    word-break: keep-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}


`, "",{"version":3,"sources":["webpack://./src/components/problem_console/problem_components/QuestionDisplay.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["/* src/components/QuestionDisplay.css */\n.question-section {\n    padding: 10px;\n    text-align: left;\n    word-break: keep-all;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
