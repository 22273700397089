import Navbar from "../common/Navbar";
import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import { useSettings } from "../common/SettingsContext";
import UpdateUserComponent from "./UpdateUserComponent";
import GenericAsync from "../backend/GenericAsync";

const AdminPage = () => {
    const [selection, setSelection] = useState({
        action: "",
    });
    const [userInfo, setUserInfo] = useState({});
    const [isBlurred, setIsBlurred] = useState(false);
    const { settings } = useSettings();
    const [allUsers, setAllUsers] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [selectedUser, setSelectedUser] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelection({ ...selection, [name]: value });
        // Reset user info
        setUserInfo({});
    };

    const handleSelectUser = (event) => {
        const email = event.target.value;
        setSelectedUser(email);
        getUserInfo(email);
    };

    const getUserInfo = async (userEmail) => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-user-info`,
                dataToSend: {
                    userEmail: userEmail,
                },
            });
            setUserInfo(response);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getAllUsers = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-all-users`,
                dataToSend: {
                    userEmail: settings.userEmail,
                },
            });
            setAllUsers(response.users);
        } catch (error) {
            console.log(error.message);
        }
    };

    const renderUserOptions = () => {
        const defaultOption = (
            <option key="default" value="">
                Select a user
            </option>
        );

        if (allUsers !== null && Array.isArray(allUsers)) {
            return [
                defaultOption,
                ...allUsers.map((email) => (
                    <option key={email} value={email}>
                        {email}
                    </option>
                )),
            ];
        }

        return defaultOption;
    };

    useEffect(() => {
        if (selection.action === "edit_user") {
            getAllUsers();
        } else {
            setUserInfo({});
        }
    }, [selection.action]);

    function returnContent() {
        if (
            !["terence.m.conlon@gmail.com", "nrish07@gmail.com"].includes(
                settings.userEmail,
            )
        ) {
            return <>You do not have access to this page!</>;
        } else {
            return (
                <>
                    <div className={"select-menu"}>
                        <select
                            name="action"
                            value={selection.action}
                            onChange={handleChange}
                            className="select-style"
                            style={{
                                width: "300px",
                            }}
                        >
                            <option value="" disabled>
                                Select action
                            </option>
                            <option value="add_user">Add new user</option>
                            <option value="edit_user">
                                Edit existing user
                            </option>
                        </select>
                    </div>
                    {selection.action === "edit_user" && allUsers && (
                        <div className={"select-menu"}>
                            <select
                                onChange={handleSelectUser}
                                value={selectedUser}
                                className="select-style"
                                style={{
                                    width: "300px",
                                }}
                            >
                                {renderUserOptions()}
                            </select>
                        </div>
                    )}
                    {selection.action && (
                        <div
                            className="settings-main-text"
                            style={{ paddingBottom: "60px" }}
                        >
                            <UpdateUserComponent
                                action={selection.action}
                                userInfo={userInfo}
                                setUserInfo={setUserInfo}
                            />
                        </div>
                    )}
                </>
            );
        }
    }

    return (
        <div className="desktop-homepage">
            <Navbar setIsBlurred={setIsBlurred} />
            <div className={`main-content ${isBlurred ? "blur-effect" : ""}`}>
                <div className="main-body-text">
                    <div
                        className="welcome-banner"
                        style={{ marginBottom: "50px" }}
                    >
                        Admin
                    </div>
                    <div className="admin-container">{returnContent()}</div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AdminPage;
