import "./JourneyInfoModal.css";
import { TestInfoContainer } from "../settings/settings_pages/account_components/TestInfo";
import { PersonalInfoContainer } from "../settings/settings_pages/account_components/PersonalInfo";
import React, { useEffect } from "react";

const SettingsModal = ({
    settings,
    setSettings,
    settingsSaveAbility,
    isModalOpen,
    setIsModalOpen,
    saveSettings,
    studentDict,
    setStudentDict,
}) => {
    const handleSaveSettings = () => {
        // Will load journeys
        setSettings({ ...settings });

        setStudentDict({
            ...studentDict,
            [settings.userEmail]: `${settings.userFirstName} ${settings.userLastName}`,
        });

        setIsModalOpen(false);
        saveSettings();
    };

    useEffect(() => {
        if (isModalOpen) {
            // Disable scrolling on the body when modal is open
            document.body.style.overflow = "hidden";
        } else {
            // Re-enable scrolling when modal is closed
            document.body.style.overflow = "";
        }

        // Cleanup function to re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = "";
        };
    }, [isModalOpen]);

    if (!isModalOpen || !settings) {
        return null;
    }

    return (
        <div className="journey-modal-backdrop">
            <div
                className="journey-modal-content"
                style={{
                    background: "#FFFFFF",
                    border: "2px solid #016DEA",
                }}
            >
                <div
                    className="journey-modal-banner-top"
                    style={{ marginBottom: "25px", padding: "0px" }}
                >
                    Welcome to the Alia Platform!
                </div>
                <div
                    style={{
                        marginBottom: "25px",
                        textAlign: "center",
                        fontSize: "16px",
                        width: "92%",
                    }}
                >
                    Fill in the following information so we can best design your
                    preparation journey.
                </div>
                <PersonalInfoContainer
                    settings={settings}
                    setSettings={setSettings}
                />
                <TestInfoContainer
                    settings={settings}
                    setSettings={setSettings}
                />

                <div
                    className={`orange-button ${settingsSaveAbility ? "" : "disabled"}`}
                    onClick={settingsSaveAbility ? handleSaveSettings : null}
                    disabled={!settingsSaveAbility}
                    style={{ width: "300px" }}
                >
                    Save Information
                </div>
            </div>
        </div>
    );
};

export default SettingsModal;
