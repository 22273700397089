import StudentSelector from "../auth/StudentSelector";
import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <div className="footer">
            <div className="footer-bar">
                <div className="footer-item-parent">
                    <div className="footer-item-1 hide-on-mobile-800">
                        <StudentSelector />
                    </div>
                    <div className="footer-item-2 hide-on-mobile-800">
                        Questions? Reach out to us via the icon on the right.
                    </div>
                    <div className="footer-item-3 ">
                        © 2024 Alia Education. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
