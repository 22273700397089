import "./NavConsoleConfirmModal.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ConfirmModal = ({
    isOpen,
    onConfirm,
    onClose,
    children,
    message,
    navConsole,
}) => {
    const navigate = useNavigate();
    const onSaveAndExit = () => {
        navigate("/"); // Navigate to the Home Page
    };

    if (!isOpen) return null;

    return (
        <div className="nav-modal-backdrop">
            <div
                className="nav-modal-content"
                style={{ borderRadius: "20px", color: "#016dea" }}
            >
                {navConsole && (
                    <button className="save-exit" onClick={onSaveAndExit}>
                        Save/Exit
                    </button>
                )}
                <p
                    style={{
                        color: "black",
                        fontFamily: "Times",
                        fontSize: "18px",
                        paddingTop: "24px",
                        margin: "10px",
                        marginBottom: "0px",
                    }}
                >
                    {message}
                </p>
                {children}
                <div className="nav-modal-actions nav-center-actions">
                    <button onClick={onConfirm}>Submit</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
