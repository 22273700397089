import React, { createContext, useContext, useState } from "react";

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    const triggerError = (error) => {
        setError(error);
    };

    // Provide the error and triggerError function to the rest of the app
    return (
        <ErrorContext.Provider value={{ error, triggerError }}>
            <>{children}</>
        </ErrorContext.Provider>
    );
};
