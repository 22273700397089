import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import GenericAsync from "../backend/GenericAsync";
// import { useError } from "../common/ErrorContext";
import { useNavigate } from "react-router-dom";
export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const { user } = useAuth0();
    const [loginAbility, setLoginAbility] = useState();
    const [loginLoading, setLoginLoading] = useState(true);
    // const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // const { triggerError } = useError();
    const navigate = useNavigate();

    const getLoginAbility = async () => {
        setLoginLoading(false);
        setLoginAbility(true);

        // UNCOMMENT: To redirect the user to Stripe checkout if they haven't subbed.
        // try {
        //     const response = await GenericAsync({
        //         backendUrl: `${backendUrl}/api/get-login-ability`,
        //         dataToSend: {
        //             userEmail: user.email,
        //         },
        //     });
        //     setLoginAbility(response);
        // } catch (error) {
        //     triggerError(error.message);
        // } finally {
        //     setLoginLoading(false);
        // }
    };

    useEffect(() => {
        if (user?.email) {
            getLoginAbility();
        }
    }, [user?.email]);

    useEffect(() => {
        if (user?.email && loginAbility === false) {
            navigate("/checkout");
        }
    }, [user?.email, loginAbility]);

    return (
        <LoginContext.Provider
            value={{ loginAbility, setLoginAbility, loginLoading }}
        >
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginContext = () => useContext(LoginContext);
