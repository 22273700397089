import "./ConfirmModal.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ConfirmModal = ({
    isOpen,
    onConfirm,
    children,
    message,
    navConsole,
    confirmMessage,
    declineMessage = null,
    onClose = null,
    showActions = true,
    maxWidth = null,
}) => {
    const navigate = useNavigate();
    const onSaveAndExit = () => {
        navigate("/");
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content" style={{ maxWidth: maxWidth }}>
                {navConsole && (
                    <button className="save-exit" onClick={onSaveAndExit}>
                        Save/Exit
                    </button>
                )}
                <div
                    style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                        lineHeight: "24px",
                    }}
                >
                    {message}
                </div>
                {children}
                {showActions && (
                    <div className="modal-actions center-actions">
                        <button onClick={onConfirm}>{confirmMessage}</button>
                        {onClose ? (
                            <button onClick={onClose}>{declineMessage}</button>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfirmModal;
