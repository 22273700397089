import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./GenerateProblemSets.css";
import { useUserProfile } from "../../auth/UserProfileContext";
import GenericAsync from "../../backend/GenericAsync";
import ConfirmModal from "../../popups/ConfirmModal";
import formatString from "../../utils/TextProcessing";
import "../../common/Style.css";
import { useError } from "../../common/ErrorContext";
import { useJourney } from "../../common/JourneyContext";

const GenerateProblemSets = () => {
    const { profile } = useUserProfile();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();

    const [sectionsData, setSectionsData] = useState(null);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState("foundations");
    const { triggerError } = useError();

    // State for managing the open/closed state of each section
    const [openSections, setOpenSections] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalStage, setModalStage] = useState(1); // 1 for selecting topics, 2 for generating test
    const { setJourney, generateAbility } = useJourney();

    // Function to toggle section open/close
    const toggleSection = (sectionKey) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [sectionKey]: !prevState[sectionKey],
        }));
    };

    const fetchCategories = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/retrieve-categories`,
                dataToSend: {},
            });
            setSectionsData(response);
        } catch (error) {
            triggerError(error.message);
        }
    };

    const handleCheckboxChange = (topic) => {
        setSelectedTopics((prevSelectedTopics) => {
            if (prevSelectedTopics.includes(topic)) {
                return prevSelectedTopics.filter((t) => t !== topic);
            } else {
                return [...prevSelectedTopics, topic];
            }
        });
    };

    const renderSubSection = (subSectionTitle, handleCheckboxChange) => (
        <div className="topic" key={subSectionTitle}>
            <input
                type="checkbox"
                id={subSectionTitle}
                onChange={() => handleCheckboxChange(subSectionTitle)}
            />
            <label htmlFor={subSectionTitle}>
                {formatString(subSectionTitle)}
            </label>
        </div>
    );

    const renderSection = (sectionTitle, details, handleCheckboxChange) => {
        if (typeof details !== "object" || details === null) {
            console.error(
                "Expected details to be an object, but received:",
                details,
            );
            return null; // or render an error message
        }

        if (typeof details !== "object" || details === null) {
            console.error(
                "Expected details to be an object, but received:",
                details,
            );
            return null; // or render an error message
        }

        return (
            <div className="subsection">
                {/* Toggleable section title */}
                <div
                    onClick={() => toggleSection(sectionTitle)}
                    style={{
                        fontSize: "18px",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <span
                        className={`triangle ${openSections[sectionTitle] ? "down" : "right"}`}
                    ></span>
                    {formatString(sectionTitle)}
                </div>

                {openSections[sectionTitle] &&
                    Object.entries(details).map(([, subSectionValue]) => {
                        return renderSubSection(
                            subSectionValue,
                            handleCheckboxChange,
                        );
                    })}
            </div>
        );
    };

    const renderDomain = (domainTitle, domains, handleCheckboxChange) => {
        if (typeof domains !== "object" || domains === null) {
            console.error(
                "Expected domains to be an object, but received:",
                domains,
            );
            return null; // or render an error message
        }
        // Format the domain title if it's 'reading_writing'
        const formattedTitle =
            domainTitle === "reading_writing"
                ? "Reading and Writing"
                : formatString(domainTitle);

        return (
            <div className="section">
                <div
                    className={"container-section-title"}
                    style={{ marginTop: "30px" }}
                >
                    {formattedTitle}
                </div>
                {Object.entries(domains).map(([sectionTitle, details]) => (
                    <div key={sectionTitle}>
                        {renderSection(
                            sectionTitle,
                            details,
                            handleCheckboxChange,
                        )}
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (profile.email) {
            fetchCategories();
        }
    }, [profile]);

    const isGenerateButtonEnabled =
        selectedTopics.length >= 1 &&
        selectedTopics.length <= 5 &&
        generateAbility;

    const handleGeneratePset = async () => {
        setModalStage(2); // Switch to generating state

        try {
            const result = await GenericAsync({
                backendUrl: `${backendUrl}/api/generate-new-step`,
                dataToSend: {
                    userEmail: profile.email,
                    stepType: "problem_sets",
                    selection: {
                        selectedCategories: selectedTopics,
                        selectedDifficulty: selectedDifficulty,
                    },
                },
            });
            if (result.success) {
                setShowConfirmModal(false); // Close the modal after generation
                // Navigate to the new module
                const activeStep = result.next_step[0];
                navigate(`/${activeStep.url_ext}`, { state: { activeStep } });

                // Update journey
                const newJourney = result.journey;
                setJourney(newJourney);
            }
        } catch (error) {
            triggerError(error.message);
        }
    };

    const onClosePopup = () => {
        setShowConfirmModal(false); // Simply close the modal
        setModalStage(1); // Reset to initial stage
    };

    const onPrepareGenerateTest = () => {
        setShowConfirmModal(true);
        setModalStage(1); // Ensure we're on the first stage
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setSelectedDifficulty(value);
    };

    if (!sectionsData) {
        return (
            <div className="single-task">
                <div className="task-title" style={{ marginTop: "40px" }}>
                    Loading...
                </div>
            </div>
        );
    }

    return (
        <div
            className="single-task"
            style={{ fontFamily: "DM Sans", fontWeight: "400", width: "90%" }}
        >
            <div>
                {sectionsData &&
                    Object.entries(sectionsData)
                        .sort(([key1], [key2]) =>
                            key1 === "reading_writing"
                                ? -1
                                : key2 === "reading_writing"
                                  ? 1
                                  : 0,
                        )
                        .map(([title, domains]) => (
                            <div key={title}>
                                {renderDomain(
                                    title,
                                    domains,
                                    handleCheckboxChange,
                                )}
                            </div>
                        ))}
            </div>
            <div>
                <div
                    className="container-section-title"
                    style={{
                        fontStyle: "normal",
                        marginTop: "20px",
                        fontWeight: "500",
                    }}
                >
                    Pick up to 5 categories
                </div>
                <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                    <div className={"dropdown-container-section-title"}>
                        Question Difficulty
                    </div>
                    <select
                        name="difficulty"
                        value={selectedDifficulty}
                        onChange={handleChange}
                        className="select-style"
                        style={{ width: "100%" }}
                    >
                        <option value="foundations">Foundations</option>
                        <option value="medium">Medium</option>
                        <option value="advanced">Advanced</option>
                    </select>
                </div>
                <div>
                    <button
                        className={`orange-button ${isGenerateButtonEnabled ? "" : "disabled"}`}
                        onClick={
                            isGenerateButtonEnabled
                                ? onPrepareGenerateTest
                                : null
                        }
                        disabled={!isGenerateButtonEnabled || !generateAbility}
                    >
                        GENERATE
                    </button>
                </div>
                <ConfirmModal
                    isOpen={showConfirmModal}
                    onConfirm={modalStage === 1 ? handleGeneratePset : null}
                    onClose={onClosePopup}
                    message={
                        modalStage === 1
                            ? "Confirm the selected categories for your Problem Set."
                            : "Generating new problem set..."
                    }
                    confirmMessage={
                        modalStage === 1 ? "Generate Problem Set" : ""
                    }
                    declineMessage="Cancel"
                    showActions={modalStage === 1}
                >
                    {modalStage === 1 && (
                        <ul>
                            {selectedTopics.map((topic) => (
                                <li key={topic}>{formatString(topic)}</li>
                            ))}
                        </ul>
                    )}
                </ConfirmModal>
            </div>
        </div>
    );
};

export default GenerateProblemSets;
