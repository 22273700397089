// Queue to hold pending requests
const requestQueue = [];
let isProcessingQueue = false;

const processQueue = async () => {
    if (isProcessingQueue || requestQueue.length === 0) {
        return;
    }

    isProcessingQueue = true;

    while (requestQueue.length > 0) {
        const currentRequest = requestQueue.shift(); // Get the oldest request
        const { backendUrl, dataToSend } = currentRequest;

        try {
            const response = await fetch(`${backendUrl}/api/submit-info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            });
            await response.json(); // Assuming you want to wait for the response
        } catch (error) {
            console.error("Error:", error);
        }
    }

    isProcessingQueue = false;
};

const sendInfoToFlask = async ({
    backendUrl,
    userEmail,
    step,
    module,
    questionIndex,
    questionId,
    questionType,
    multipleChoiceAnswer,
    numericAnswer,
    timeRemaining,
    action,
}) => {
    const dateTime = new Date().toISOString();

    const dataToSend = {
        userEmail,
        step,
        module,
        questionIndex,
        questionId,
        questionType,
        multipleChoiceAnswer,
        numericAnswer,
        dateTime,
        timeRemaining,
        action,
    };

    // Add request to the queue
    requestQueue.push({ backendUrl, dataToSend });

    // Attempt to process the queue
    processQueue();
};

export default sendInfoToFlask;
