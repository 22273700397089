import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../auth/UserProfileContext";
import "./Style.css";
import ConfirmModal from "../popups/ConfirmModal";
import React, { useEffect, useState } from "react";

const ClickThroughButton = ({ activeStep, className, style }) => {
    const [validClickThrough, setValidClickThrough] = useState(true);
    const [tutorModeModal, setTutorModeModal] = useState(false);
    const navigate = useNavigate();
    const { profile } = useUserProfile();

    function buttonText() {
        if (activeStep.status === "not_started") {
            return "Start";
        } else if (activeStep.status === "not_submitted") {
            return "Resume";
        } else if (activeStep.status === "revising") {
            return "Revise";
        }
    }

    function returnClickThroughText() {
        if (validClickThrough) {
            return (
                "You are currently in Tutor Mode. You will be able to " +
                "view the following content, but any actions or submissions " +
                "you make will not be tracked."
            );
        } else {
            return (
                "You are currently in Tutor Mode, and will not be able to see the " +
                "following test until your student submits it. "
            );
        }
    }

    const navigateToStep = () => {
        navigate(`/${activeStep.url_ext}`, { state: { activeStep } });
    };

    const handleGoToStep = () => {
        if (profile.tutorMode) {
            setTutorModeModal(true);
        } else {
            navigateToStep();
        }
    };

    const handleTutorModeModal = () => {
        setTutorModeModal(false);
    };

    useEffect(() => {
        if (
            activeStep.status !== "revising" &&
            activeStep.step_type === "tests"
        ) {
            setValidClickThrough(false);
        } else {
            setValidClickThrough(true);
        }
    }, [activeStep]);

    return (
        <>
            <button
                className={className}
                style={style}
                onClick={() => handleGoToStep()}
            >
                {buttonText()}
            </button>
            <ConfirmModal
                isOpen={tutorModeModal}
                onConfirm={
                    validClickThrough ? navigateToStep : handleTutorModeModal
                }
                message={returnClickThroughText()}
                confirmMessage={validClickThrough ? "Continue" : "Go Back"}
            ></ConfirmModal>
        </>
    );
};

export default ClickThroughButton;
