import React, { useState, useEffect } from "react";
import { useJourney } from "../../common/JourneyContext";
import RenderWithLineBreaks from "../../utils/RenderWithLineBreaks";
import SelectStepContainer from "./SelectStepContainer";

const ExistingContainer = ({ stepType }) => {
    const { journey } = useJourney();
    const [currentSteps, setCurrentSteps] = useState(null);
    const [completeSteps, setCompleteSteps] = useState(null);
    const [activeStep, setActiveStep] = useState(null);
    const [containerHeader, setContainerHeader] = useState(null);

    useEffect(() => {
        if (journey) {
            // Filter the journey to get entries with 'step_type' equal to 'stepType'
            const filteredCurrentSteps = Object.values(journey).filter(
                (entry) =>
                    entry.step_type === stepType.replaceAll("-", "_") &&
                    entry.status !== "complete",
            );

            const filteredCompleteSteps = Object.values(journey).filter(
                (entry) =>
                    entry.step_type === stepType.replaceAll("-", "_") &&
                    entry.status === "complete",
            );

            // Sort the filtered steps by the 'step' attribute
            const sortedCurrentSteps = filteredCurrentSteps.sort(
                (a, b) => a.step - b.step,
            );
            const sortedCompleteSteps = filteredCompleteSteps.sort(
                (a, b) => a.step - b.step,
            );

            // Update the state with the sorted steps
            setCurrentSteps(sortedCurrentSteps);
            setCompleteSteps(sortedCompleteSteps);
        }
    }, [journey, stepType]);

    useEffect(() => {
        if (currentSteps && currentSteps.length > 0) {
            setActiveStep(currentSteps[0]);
        } else {
            setActiveStep(null);
        }
    }, [currentSteps, stepType]);

    useEffect(() => {
        if (activeStep) {
            if (activeStep.status === "complete") {
                setContainerHeader(
                    `COMPLETED ${stepType.replace(/-/g, " ").toUpperCase()}`,
                );
            } else {
                setContainerHeader(
                    `CURRENT ${stepType.replace(/-/g, " ").toUpperCase()}`,
                );
            }
        }
    }, [journey, stepType, activeStep]);

    function DropDown(steps, setActiveStep) {
        const [showAll, setShowAll] = useState(false);

        if (!steps) {
            return null;
        }

        return (
            <>
                {steps
                    .slice(0, showAll ? steps.length : 5)
                    .map((step, index) => (
                        <div
                            className="dropdown-text"
                            key={index}
                            onClick={() => setActiveStep(step)}
                        >
                            {step.step_name}
                        </div>
                    ))}
                {!showAll && steps.length > 5 && (
                    <div
                        className="show-all-link"
                        onClick={() => setShowAll(true)}
                    >
                        Show all...
                    </div>
                )}
                {showAll && (
                    <div
                        className="show-all-link"
                        onClick={() => setShowAll(false)}
                    >
                        Collapse all...
                    </div>
                )}
            </>
        );
    }

    function SingleStepReturn(activeStep, stepType) {
        if (!activeStep) {
            return (
                <div className="single-task">
                    <div className="task-title" style={{ marginTop: "40px" }}>
                        No current {stepType.replace("-", " ")}!
                    </div>
                </div>
            );
        } else {
            return (
                <SelectStepContainer
                    activeStep={activeStep}
                    stepType={stepType}
                />
            );
        }
    }

    function returnCopy(stepType) {
        let text_copy;
        let stepTypeSingular;
        if (stepType === "tests") {
            stepTypeSingular = "test";
            text_copy =
                "" +
                "Tests are identical in format to the DSAT as administered by College Board. These Tests have four " +
                "modules — two Reading and Writing, followed by two Math. The second module for each question type is adaptive, " +
                "meaning it changes based on your performance on the first module. \n\n";
        } else if (stepType === "modules") {
            stepTypeSingular = "module";
            text_copy =
                "" +
                "Modules split out the individual test sections for targeted practice. Here, you can complete " +
                "modules for both question types — Reading and Writing, and Math — at three levels of difficulty " +
                "— Foundations, Medium, and Advanced. These modules are administered 1 by " +
                "1, unlike the Tests, which are given four modules at a time. \n\n";
        } else if (stepType === "problem-sets") {
            stepTypeSingular = "problem set";
            text_copy =
                "" +
                "Problem sets contain a collection of practice problems, organized by category. \n\n";
        }

        text_copy +=
            "" +
            `Once you have completed a ${stepTypeSingular}, you will be able to see an overview ` +
            "and download a full report of your results. Note: the overviews displayed here represent " +
            "your first submission! They do not include answers corrected via revisions.";

        return text_copy;
    }

    return (
        <div className={"two-col-body-content"}>
            <div
                className={"two-col-body-content-text"}
                style={{ textAlign: "left" }}
            >
                <div style={{ marginBottom: "40px" }}>
                    {RenderWithLineBreaks(returnCopy(stepType))}
                </div>
                <div className="two-col-horizontal-container">
                    <div className={"two-col-body-content-columns"}>
                        <div
                            style={{
                                fontSize: 18,
                                textDecoration: "underline",
                            }}
                        >
                            CURRENT
                        </div>
                        <div className="two-col-body-content-text-list">
                            {DropDown(currentSteps, setActiveStep)}
                        </div>
                    </div>
                    <div className={"two-col-body-content-columns"}>
                        <div
                            style={{
                                fontSize: 18,
                                textDecoration: "underline",
                            }}
                        >
                            COMPLETED
                        </div>
                        <div className="two-col-body-content-text-list">
                            {DropDown(completeSteps, setActiveStep)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"two-col-body-content-container"}>
                <div className={"task-container"} style={{ marginTop: "0px" }}>
                    <div className={"overlap"} style={{ width: "100%" }}>
                        <div className="rectangle">
                            <div>
                                <div className="task-container-title">
                                    {containerHeader}
                                </div>
                            </div>
                        </div>
                        <div
                            className="current-task-container"
                            style={{
                                justifyContent: "flex-start",
                                minHeight: "140px",
                                maxWidth: "800px",
                                width: "100%",
                            }}
                        >
                            {SingleStepReturn(activeStep, stepType)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExistingContainer;
