import React from "react";

export const TutorInfoContainer = ({ settings, setSettings }) => {
    return (
        <div className="settings-unit">
            <div className="settings-container-title">Tutor Info</div>
            <div className={"settings-text-container"}>
                <div className={"settings-container-columns"}>
                    <div className="settings-text-column">
                        <div className="settings-column-title">Tutor Name:</div>
                        <textarea
                            className={"settings-select-style"}
                            style={{
                                marginBottom: "10px",
                                resize: "none",
                                height: "36px",
                            }}
                            value={settings.tutorName ?? ""}
                            onChange={(e) =>
                                setSettings({
                                    ...settings,
                                    tutorName: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="settings-text-column">
                        <div className="settings-column-title">
                            Tutor Email:
                        </div>
                        <textarea
                            className={"settings-select-style"}
                            style={{
                                marginBottom: "10px",
                                resize: "none",
                                height: "36px",
                            }}
                            value={settings.tutorEmail ?? ""}
                            onChange={(e) =>
                                setSettings({
                                    ...settings,
                                    tutorEmail: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
