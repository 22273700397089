import "./JourneyInfoModal.css";

const JourneyInfoModal = ({ isInfoOpen, setIsInfoOpen }) => {
    if (!isInfoOpen) return null;

    return (
        <div className="journey-modal-backdrop">
            <div
                className="journey-modal-content"
                style={{ background: "white", border: "2px solid #016dea" }}
            >
                <button
                    className="journey-modal-close-button"
                    onClick={() => setIsInfoOpen(false)}
                >
                    X
                </button>
                <div className="journey-modal-banner-top">
                    “My Journey” is a guided study path to help you achieve your
                    highest possible SAT score.
                </div>
                <div className="journey-modal-columns">
                    <div className="journey-modal-column">
                        <div className="journey-modal-title">ABOUT:</div>
                        <p>
                            My Journey can either be fully-automated or
                            user-directed. Based on your use of the Alia
                            platform, our team will continually feed you new
                            questions that we believe will best target your
                            knowledge gaps. This way, you don&apos;t have to
                            make any decisions about what to study — we&apos;ll
                            handle that.
                        </p>
                        <p>
                            Alternatively, if you feel like you want to generate
                            some study questions particular to an area of
                            interest, please feel free to do so via the
                            navigation bar on top. These steps will also count
                            towards your journey!
                        </p>
                    </div>
                    <div className="journey-modal-column">
                        <div className="journey-modal-title">HOW TO USE:</div>
                        <p>
                            My Journey is made up of units to best track your
                            progress. Each unit will start with an official
                            test, but after that, the order will be flexible.
                        </p>
                        <p>
                            My Journey is structured in a way that helps you
                            achieve mastery of learning. Before moving onto the
                            next step in your journey, we require that you
                            complete the revisions for the task at hand. This
                            way, you know you&apos;ll have a handle on previous
                            material before moving to what’s next.
                        </p>
                    </div>
                </div>
                <div className="journey-modal-banner-bottom">
                    Our main piece of advice for you is to be persistent —
                    staying focused and engaged is the best way to maximize your
                    preparation.
                </div>

                <div
                    className="orange-button"
                    style={{ width: "300px" }}
                    onClick={() => setIsInfoOpen(false)}
                >
                    LET&apos;S DO THIS!
                </div>
            </div>
        </div>
    );
};

export default JourneyInfoModal;
