function formatUTCTime(input) {
    // Parse the input string to match the format 'YYYY-MM-DDTHH-MM-SS'
    if (!input) return;

    const parts = input.match(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})/,
    );
    if (!parts) return "Invalid date format";

    // Create a Date object from the parts
    const date = new Date(
        Date.UTC(
            parts[1],
            parts[2] - 1,
            parts[3],
            parts[4],
            parts[5],
            parts[6],
        ),
    );

    // Format the date into a readable format '12:00p January 1, 2024'
    const options = {
        hour: "numeric",
        minute: "numeric",
        day: "numeric",
        month: "long",
        year: "numeric",
    };

    return date.toLocaleString("en-US", options);
}

export function formatDateTimeObject(dateString) {
    if (!dateString) return;

    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
}

export default formatUTCTime;
