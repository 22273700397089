import React, { useState, useRef, useEffect } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useAuth0 } from "@auth0/auth0-react";
import { useError } from "../common/ErrorContext";
import { useSettings } from "../common/SettingsContext";

const TermsAndConditions = () => {
    const { settings, setSettings, isModalOpen } = useSettings();
    const [isEnabled, setIsEnabled] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const termsRef = useRef(null);
    const { user } = useAuth0();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [termsHtml, setTermsHtml] = useState("");
    const { triggerError } = useError();

    useEffect(() => {
        fetch("/Terms and Conditions of Use.html")
            .then((response) => response.text())
            .then((text) => setTermsHtml(text));
    }, []);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setIsEnabled(true); // Enable the button when scrolled to the bottom
        }
    };

    const handleSubmit = async () => {
        const dataToSend = {
            userEmail: user.email,
        };

        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/submit-terms-and-conditions`,
                dataToSend: dataToSend,
            });
            setIsAccepted(true);
        } catch (error) {
            triggerError(error.message);
        }

        setSettings({ ...settings, termsAcknowledged: true });
    };

    useEffect(() => {
        if (settings.termsAcknowledged) {
            setIsAccepted(true);
        }
    }, [settings.termsAcknowledged]);

    useEffect(() => {
        if (!isAccepted) {
            document.body.classList.add("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [isAccepted]);

    if (!settings || isAccepted || isModalOpen || settings.termsAcknowledged) {
        return;
    }

    return (
        <>
            <div className="overlay"></div>
            <div
                className="terms-conditions"
                ref={termsRef}
                onScroll={handleScroll}
            >
                <h2 className="terms-title">Terms and Conditions</h2>
                <div dangerouslySetInnerHTML={{ __html: termsHtml }} />
                <button
                    className="agree-button"
                    onClick={handleSubmit}
                    disabled={!isEnabled}
                >
                    Agree
                </button>
            </div>
        </>
    );
};

export default TermsAndConditions;
