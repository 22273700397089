import React, { useEffect, useState } from "react";
import MathJaxWrapper from "../../utils/MathJaxWrapper";
import "./AnswerChoices.css";
import sendInfoToFlask from "../../backend/SendInfoToFlask";
import GetImageSrc from "../../utils/GetImageSrc";

const AnswerChoices = ({
    choices,
    explanations,
    selectedAnswer,
    questionFormat,
    onSelectAnswer,
    strikeThroughMode,
    viewAnswerMode,
    profile,
    step,
    module,
    questionIndex,
    questionId,
    questionType,
    timeRemaining,
    correctRadioAnswer,
    correctNumericAnswer,
}) => {
    const letters = ["A", "B", "C", "D", "numeric"];
    const [numericAnswer, setNumericAnswer] = useState("");
    // Initialize struckAnswers based on choices length, reset when choices change
    const [struckAnswers, setStruckAnswers] = useState(
        new Array(choices.length).fill(false),
    );
    const [viewedExplanations, setViewedExplanations] = useState(
        new Array(5).fill(false),
    );
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        if (questionFormat === "numeric") {
            setNumericAnswer(selectedAnswer || "");
        }
    }, [selectedAnswer, questionFormat]);

    useEffect(() => {
        // Reset struckAnswers when choices change
        setStruckAnswers(new Array(choices.length).fill(false));
    }, [choices]);

    useEffect(() => {
        // Set viewed explanations to False
        setViewedExplanations(new Array(5).fill(false));
    }, [questionIndex]);

    const toggleStrikethrough = (index) => {
        const updatedStruckAnswers = struckAnswers.map((item, idx) =>
            idx === index ? !item : item,
        );
        setStruckAnswers(updatedStruckAnswers);

        const action = updatedStruckAnswers[index]
            ? `answer_${letters[index]}_strikethrough`
            : `answer_${letters[index]}_remove_strikethrough`;

        if (!profile.tutorMode) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                module: module,
                questionIndex: questionIndex,
                questionId: questionId,
                questionType: questionType,
                timeRemaining: timeRemaining,
                action: action,
            });
        }
    };

    const toggleViewExplanation = (index) => {
        const updatedViewedExplanations = viewedExplanations.map((item, idx) =>
            idx === index ? !item : item,
        );
        setViewedExplanations(updatedViewedExplanations);

        const action = updatedViewedExplanations[index]
            ? `explanation_${letters[index]}_view`
            : `explanation_${letters[index]}_hide`;

        if (!profile.tutorMode) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                module: module,
                questionIndex: questionIndex,
                questionId: questionId,
                questionType: questionType,
                timeRemaining: timeRemaining,
                action: action,
            });
        }
    };

    const renderChoice = (choice) => {
        if (choice.length > 500) {
            return (
                <div
                    className="image-container"
                    style={{ transform: "translateX(-15px)" }}
                >
                    <img src={GetImageSrc(choice)} alt="Question Image" />
                </div>
            );
        } else {
            return (
                <div
                    className="choice-text"
                    dangerouslySetInnerHTML={{ __html: choice }}
                ></div>
            );
        }
    };

    const renderNumericExplanation = (explanation) => {
        return (
            <div className="explanation-wrapper">
                <div className="explanation-container">
                    <div className="explanation-title correct-answer">
                        Correct Answer: {correctNumericAnswer}
                    </div>
                    <div className="explanation-text">{explanation}</div>
                </div>
            </div>
        );
    };

    const renderRadioExplanation = (index, explanation) => {
        const answer_choices = ["A", "B", "C", "D"];
        const isCorrect = answer_choices[index] === correctRadioAnswer;
        const titleClass = isCorrect ? "correct-answer" : "incorrect-answer";

        return (
            <div className="explanation-wrapper">
                <div className="explanation-container">
                    <div className={`explanation-title ${titleClass}`}>
                        {isCorrect
                            ? `Answer ${answer_choices[index]}: Correct`
                            : `Answer ${answer_choices[index]}: Incorrect`}
                    </div>
                    <div className="explanation-text">{explanation}</div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`choices-container ${strikeThroughMode || viewAnswerMode ? "strikeThroughMode" : ""}`}
        >
            {questionFormat === "radio" &&
                choices.map((choice, index) => (
                    <MathJaxWrapper key={index}>
                        <div className="answer-wrapper">
                            <button
                                className={`choice ${selectedAnswer === letters[index] ? "selected" : ""} ${struckAnswers[index] ? "struck" : ""}`}
                                onClick={() => onSelectAnswer(letters[index])}
                            >
                                <span className="choice-letter">
                                    {letters[index]}
                                </span>
                                {renderChoice(choice)}
                            </button>
                            {strikeThroughMode && (
                                <button
                                    className="strike-toggle"
                                    onClick={() => toggleStrikethrough(index)}
                                >
                                    {struckAnswers[index] ? (
                                        "Undo"
                                    ) : (
                                        <span
                                            style={{
                                                textDecoration: "line-through",
                                            }}
                                        >
                                            {letters[index]}
                                        </span>
                                    )}
                                </button>
                            )}
                            {viewAnswerMode && (
                                <button
                                    className="strike-toggle"
                                    onClick={() => toggleViewExplanation(index)}
                                >
                                    {viewedExplanations[index] ? (
                                        "Hide"
                                    ) : (
                                        <span>🔍</span>
                                    )}
                                </button>
                            )}
                        </div>
                        {viewedExplanations?.[index] &&
                            explanations?.[index] &&
                            renderRadioExplanation(index, explanations[index])}
                    </MathJaxWrapper>
                ))}

            {questionFormat === "numeric" && (
                <MathJaxWrapper>
                    <div
                        className="answer-wrapper"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                        <input
                            className="numeric-input"
                            type="text"
                            value={numericAnswer}
                            onChange={(e) => setNumericAnswer(e.target.value)}
                            placeholder="Enter your answer"
                        />
                        <button
                            className="save-button"
                            onClick={() => onSelectAnswer(numericAnswer)}
                        >
                            Save
                        </button>
                        {viewAnswerMode && (
                            <button
                                className="strike-toggle"
                                style={{ height: "36px" }}
                                onClick={() => toggleViewExplanation(4)}
                            >
                                {viewedExplanations[4] ? (
                                    "Hide"
                                ) : (
                                    <span>🔍</span>
                                )}
                            </button>
                        )}
                    </div>
                    {viewedExplanations?.[4] &&
                        explanations?.[4] &&
                        renderNumericExplanation(explanations[4])}
                </MathJaxWrapper>
            )}
        </div>
    );
};

export default AnswerChoices;
