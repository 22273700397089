import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import TermsAndConditions from "../auth/TermsAndConditions";
import Footer from "../common/Footer";
import MyJourney from "./MyJourney";
import { useSettings } from "../common/SettingsContext";
import { updateIntercomUser } from "../common/intercom/IntercomUtils";
import { useJourney } from "../common/JourneyContext";

const HomePage = () => {
    const [isBlurred, setIsBlurred] = useState(false);
    const {
        settings,
        setSettings,
        isModalOpen,
        settingsSaveAbility,
        subscriptionBannerShown,
    } = useSettings();
    const { journey } = useJourney();

    useEffect(() => {
        if (settings?.userEmail) {
            updateIntercomUser(settings);
        }
    }, [settings?.userEmail]);

    useEffect(() => {
        if (
            settings?.viewTour &&
            journey &&
            settings?.termsAcknowledged &&
            !isModalOpen &&
            settingsSaveAbility
        ) {
            if (window.Intercom) {
                window.Intercom("startTour", 546360);
                setSettings({ ...settings, viewTour: false });
            }
        }
    }, [journey, settings?.viewTour, settings?.termsAcknowledged, isModalOpen]);

    if (!settings?.userEmail) {
        return <div>Loading....</div>;
    }

    return (
        <div
            className="desktop-homepage"
            style={{ overflow: isModalOpen ? "hidden" : "auto" }}
        >
            <TermsAndConditions />
            <Navbar setIsBlurred={setIsBlurred} />
            <div
                className={`main-content ${isBlurred ? "blur-effect" : ""}`}
                style={
                    subscriptionBannerShown
                        ? { paddingTop: "140px" }
                        : { paddingTop: "100px" }
                }
            >
                <div className="welcome-banner">
                    {settings.userFirstName && !isModalOpen && (
                        <span>Welcome back, {settings.userFirstName}!</span>
                    )}
                </div>
                <div className="home-page-journey">
                    <MyJourney />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
