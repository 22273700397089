import React, { createContext, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useSettings } from "./SettingsContext";

const SocketContext = createContext(null);
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const SocketProvider = ({ children }) => {
    const { settings } = useSettings();
    const socketRef = useRef(null);

    useEffect(() => {
        if (settings?.userEmail) {
            socketRef.current = io(backendUrl, {
                transports: ["websockets", "polling"],
                withCredentials: true,
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 1000,
            });

            socketRef.current.on("connect", () => {
                // console.log("Socket connected");
                socketRef.current.emit("join", {
                    user_email: settings.userEmail,
                });
            });

            socketRef.current.on("disconnect", () => {
                // console.log("Socket disconnected");
            });

            return () => {
                if (socketRef.current) {
                    socketRef.current.disconnect();
                }
            };
        }
    }, [settings?.userEmail]);

    const value = {
        socket: socketRef.current,
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);
