import React, { useRef, useState, useEffect } from "react";
import "./JourneyPath.css";

const JourneyUnit = ({
    unitNumber,
    totalNumberUnits,
    unit,
    complete,
    previousRowCount,
    currentStepIndex,
    setIsStepOpen,
    setSelectedStep,
}) => {
    const circle_radius = 20;
    const side_length = 80;

    const unitRowContainerRef = useRef(null);
    const [fullWidth, setFullWidth] = useState(0);
    const [stepGaps, setStepGaps] = useState(null);
    const [unitRows, setUnitRows] = useState(null);

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setUnitRows(splitArrayIntoSublists(unit, complete));
    }, [unit, complete]);

    useEffect(() => {
        function handleResize() {
            if (unitRowContainerRef.current && unitRows) {
                const containerWidth = unitRowContainerRef.current.offsetWidth; // small offset for overlap
                setFullWidth(containerWidth);
                const gaps = unitRows.map(
                    (sublist) =>
                        (containerWidth - 2 * side_length) /
                        (sublist.length - 1),
                );
                setStepGaps(gaps);
                setIsReady(true); // Ensure the UI is updated only after setting the steps
            }
        }

        if (unitRowContainerRef.current) {
            handleResize();
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [unitRows]); // Depends on unitRows, which is set from unit and complete

    function splitArrayIntoSublists(arr, complete) {
        const MAX_ITEMS_PER_SUBLIST = 7;

        if (arr.length <= MAX_ITEMS_PER_SUBLIST) {
            return [arr];
        }

        let result = [];
        if (!complete) {
            // Naive splitting
            for (let i = 0; i < arr.length; i += MAX_ITEMS_PER_SUBLIST) {
                result.push(arr.slice(i, i + MAX_ITEMS_PER_SUBLIST));
            }
        } else {
            // Equal distribution splitting
            let numSublists = Math.ceil(arr.length / MAX_ITEMS_PER_SUBLIST);
            let itemsPerSublist = Math.floor(arr.length / numSublists);
            let extraItems = arr.length % numSublists;

            let startIndex = 0;
            for (let i = 0; i < numSublists; i++) {
                let endIndex =
                    startIndex + itemsPerSublist + (extraItems > 0 ? 1 : 0);
                result.push(arr.slice(startIndex, endIndex));
                startIndex = endIndex;
                if (extraItems > 0) {
                    extraItems--;
                }
            }
        }

        return result;
    }

    function handleStepClick(step) {
        setSelectedStep(step);
        setIsStepOpen(true);
    }

    function returnUnitRow(row, rowIndex, previousRowCount, firstRow, lastRow) {
        const totalRowIndex = rowIndex + previousRowCount;
        const reverse = totalRowIndex % 2 !== 0;
        const v_offset = 80;
        const text_v_offset = 110;

        function returnStepColor(step) {
            if (step.index > currentStepIndex) {
                return "#A1A1A1";
            } else if (step.index === currentStepIndex) {
                return "#EC5725";
            } else {
                return "#016dea";
            }
        }

        function returnCircleX(index, rowIndex, lastRow) {
            let xGap;
            if (lastRow) {
                xGap = Math.min(stepGaps[rowIndex], 120);
            } else {
                xGap = stepGaps[rowIndex];
            }

            return !reverse
                ? index * xGap + side_length
                : fullWidth - (index * xGap + side_length);
        }

        function returnJourneyCurves(row, reverse, firstRow, lastRow) {
            const firstCurveIndex = row[0].index - 0.5;
            const lastCurveIndex = row[row.length - 1].index + 0.5;

            return firstRow ? (
                <path
                    d={`M ${fullWidth - side_length} ${v_offset} A ${side_length} ${side_length} 0 0 1 ${fullWidth} ${v_offset + side_length}`}
                    fill="none"
                    stroke={
                        lastCurveIndex > currentStepIndex
                            ? "#A1A1A1"
                            : "#016dea"
                    }
                    strokeWidth="2"
                    markerEnd={lastRow ? "url(#arrowhead)" : ""}
                />
            ) : !reverse ? (
                <>
                    <path
                        d={`M ${0} ${0} A ${side_length} ${side_length} 0 0 0 ${side_length} ${side_length}`}
                        fill="none"
                        stroke={
                            firstCurveIndex > currentStepIndex
                                ? "#A1A1A1"
                                : "#016dea"
                        }
                        strokeWidth="2"
                    />
                    <path
                        d={`M ${fullWidth - side_length} ${v_offset} A ${side_length} ${side_length} 0 0 1 ${fullWidth} ${v_offset + side_length}`}
                        fill="none"
                        stroke={
                            lastCurveIndex > currentStepIndex
                                ? "#A1A1A1"
                                : "#016dea"
                        }
                        strokeWidth="2"
                        markerEnd={lastRow ? "url(#arrowhead)" : ""}
                    />
                </>
            ) : (
                <>
                    <path
                        d={`M ${fullWidth} ${0} A ${side_length} ${side_length} 0 0 1 ${fullWidth - side_length} ${side_length}`}
                        fill="none"
                        stroke={
                            firstCurveIndex > currentStepIndex
                                ? "#A1A1A1"
                                : "#016dea"
                        }
                        strokeWidth="2"
                    />
                    <path
                        d={`M ${side_length} ${v_offset} A ${side_length} ${side_length} 0 0 0 ${0} ${v_offset + side_length}`}
                        fill="none"
                        stroke={
                            lastCurveIndex > currentStepIndex
                                ? "#A1A1A1"
                                : "#016dea"
                        }
                        strokeWidth="2"
                        markerEnd={lastRow ? "url(#arrowhead)" : ""}
                    />
                </>
            );
        }

        return (
            <div className="journey-row-ref" key={totalRowIndex}>
                <div
                    className="journey-row-container"
                    style={{ height: `${v_offset + side_length}px` }}
                    key={totalRowIndex}
                >
                    <svg className="journey-path-svg">
                        <>
                            <defs>
                                <marker
                                    id="arrowhead"
                                    markerWidth="10"
                                    markerHeight="7"
                                    refX="0"
                                    refY="3.5"
                                    orient="auto"
                                    fill="#A1A1A1"
                                >
                                    <polygon points="0 0, 10 3.5, 0 7" />
                                </marker>
                            </defs>
                            <line
                                x1={side_length}
                                y1={v_offset}
                                x2={fullWidth - side_length}
                                y2={v_offset}
                                stroke={"#A1A1A1"}
                                strokeWidth="2"
                            />
                            {row.map((step, index) =>
                                index > 0 ? (
                                    <line
                                        key={`line-${rowIndex}-${index}`}
                                        x1={returnCircleX(
                                            index - 1,
                                            rowIndex,
                                            lastRow,
                                        )}
                                        y1={v_offset}
                                        x2={returnCircleX(
                                            index,
                                            rowIndex,
                                            lastRow,
                                        )}
                                        y2={v_offset}
                                        stroke={
                                            step.index > currentStepIndex
                                                ? "#A1A1A1"
                                                : "#016dea"
                                        }
                                        strokeWidth="2"
                                    />
                                ) : null,
                            )}
                            {firstRow ? (
                                <line
                                    x1={0}
                                    y1={v_offset}
                                    x2={side_length}
                                    y2={v_offset}
                                    stroke="#016dea"
                                    strokeWidth="2"
                                />
                            ) : null}
                        </>
                        {returnJourneyCurves(row, reverse, firstRow, lastRow)}
                        {row.map((step, index) => (
                            <React.Fragment key={index}>
                                <circle
                                    cx={returnCircleX(index, rowIndex, lastRow)}
                                    cy={v_offset}
                                    r={circle_radius}
                                    fill={
                                        returnStepColor(step) === "#A1A1A1"
                                            ? "#fcf4e6"
                                            : returnStepColor(step)
                                    } // Stroke color
                                    stroke={returnStepColor(step)}
                                    strokeWidth={2} // Stroke width
                                    onClick={() => handleStepClick(step)}
                                />
                                <foreignObject
                                    x={
                                        returnCircleX(
                                            index,
                                            rowIndex,
                                            lastRow,
                                        ) - 50
                                    }
                                    y={text_v_offset}
                                    width={100}
                                    height={60}
                                >
                                    <div
                                        xmlns="http://www.w3.org/1999/xhtml"
                                        className={"step-description"}
                                        style={{ color: returnStepColor(step) }}
                                    >
                                        {step.step_name.split(",")[0]}
                                    </div>
                                </foreignObject>
                                {step.current_step ? (
                                    <foreignObject
                                        x={
                                            returnCircleX(
                                                index,
                                                rowIndex,
                                                lastRow,
                                            ) - 50
                                        }
                                        y={25}
                                        width={100}
                                        height={20}
                                    >
                                        <div
                                            xmlns="http://www.w3.org/1999/xhtml"
                                            className={"step-description"}
                                            style={{
                                                color: returnStepColor(step),
                                            }}
                                        >
                                            UP NEXT:
                                        </div>
                                    </foreignObject>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </svg>
                </div>
            </div>
        );
    }

    const unitSide = previousRowCount % 2 === 0;

    return (
        <div className="unit-container">
            <div
                className={`unit-rectangle${unitSide ? "" : "-empty"}`}
                style={{ marginTop: "50px" }}
            >
                {unitSide ? (
                    <>
                        <p className="unit">UNIT</p>
                        <p className="number">{unitNumber + 1}</p>
                    </>
                ) : null}
            </div>
            <div className="journey-row-stack" ref={unitRowContainerRef}>
                {isReady
                    ? unitRows.map((row, rowIndex) =>
                          returnUnitRow(
                              row,
                              rowIndex,
                              previousRowCount,
                              unitNumber === 0 && rowIndex === 0,
                              unitNumber === totalNumberUnits - 1 &&
                                  rowIndex === unitRows.length - 1,
                          ),
                      )
                    : null}
            </div>
            <div
                className={`unit-rectangle${!unitSide ? "" : "-empty"}`}
                style={{ marginTop: "50px" }}
            >
                {!unitSide ? (
                    <>
                        <p className="unit">UNIT</p>
                        <p className="number">{unitNumber + 1}</p>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default JourneyUnit;
