export const preprocessText = (text) => {
    const updated_text = text
        .split("\n\n")
        .join("<br/><br/>") // Replace double newline characters with two line breaks
        .split("\n*")
        .join("<br/>*"); // Replace newline followed by asterisk with line break and asterisk

    return updated_text;
};

function formatString(str) {
    return (
        str
            // Replace title
            .replace("reading_writing", "Reading and Writing")
            // Replace underscores with spaces
            .replace(/_/g, " ")
            // Split the string into words
            .split(" ")
            // Capitalize the first letter of each word
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            // Join the words back into a string
            .join(" ")
            // Replace capitalized ands
            .replace("And ", "and ")
            // Replace capitalized ofs
            .replace("Of ", "of ")
            // Replace capitalized ins
            .replace("In ", "in ")
            // Replace capitalized withs
            .replace("With ", "with ")
            // Special case: uppercase 'DSAT'
            .replace("Dsat", "")
            // Special case: replace PSAT text
            .replace("Official Practice Test 1", "Official PSAT")
            // Replace DSAT
            .replace("DSAT ", "")
            // Remove leading and training white space
            .trim()
            // Replace specific category names
            .replace("-text", "-Text")
    );
}

export default formatString;
