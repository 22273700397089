// src/components/QuestionDisplay.js
import "./QuestionDisplay.css";
import React from "react";
import MathJaxWrapper from "../../utils/MathJaxWrapper";

const QuestionDisplay = ({ question }) => {
    return (
        <MathJaxWrapper>
            <div
                className={"question-section"}
                dangerouslySetInnerHTML={{ __html: question }}
            />
        </MathJaxWrapper>
    );
};

export default QuestionDisplay;
