import React from "react";

function RenderWithLineBreaks(text_copy) {
    return (
        <div>
            {text_copy.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
}

export default RenderWithLineBreaks;
