import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import "./Calculator.css";

const Calculator = () => {
    const calculatorRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let calculator;
        if (isVisible) {
            const script = document.createElement("script");
            script.src =
                "https://www.desmos.com/api/v1.8/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (calculatorRef.current && window.Desmos) {
                    calculator = window.Desmos.GraphingCalculator(
                        calculatorRef.current,
                    );
                }
            };

            return () => {
                if (calculator) {
                    calculator.destroy();
                }
                document.body.removeChild(script);
            };
        }
    }, [isVisible]);

    return (
        <div className="hide-on-mobile">
            <button
                className={"header-button"}
                onClick={() => setIsVisible(!isVisible)}
            >
                {isVisible ? "Hide Calculator" : "Show Calculator"}
            </button>
            {isVisible && (
                <Draggable
                    defaultPosition={{ x: 0, y: window.innerHeight / 2 - 200 }}
                    handle=".drag-handle"
                >
                    <div
                        className="calculator-container"
                        id="calculator-container"
                    >
                        <div className="drag-handle">Drag from here</div>
                        <button
                            className="close-button"
                            onClick={() => setIsVisible(false)}
                        >
                            X
                        </button>
                        <div
                            id="calculator"
                            ref={calculatorRef}
                            style={{ width: "600px", height: "400px" }}
                        ></div>
                    </div>
                </Draggable>
            )}
        </div>
    );
};

export default Calculator;
