import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import GenericAsync from "../backend/GenericAsync";
import { useError } from "../common/ErrorContext";
import { useLoginContext } from "./LoginContext";
export const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
    const { user } = useAuth0();
    const { loginAbility } = useLoginContext();

    const [profile, setProfile] = useState({});
    const [studentDict, setStudentDict] = useState({});
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { triggerError } = useError();

    const fetchUserRelations = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/retrieve-user-relations`,
                dataToSend: {
                    userEmail: user.email,
                },
            });
            const updatedStudentDict = {
                [user.email]: user.name, // Assuming user object has a name field
                ...response.user_students,
            };
            setStudentDict(updatedStudentDict);
        } catch (error) {
            triggerError(error.message);
        }
    };

    useEffect(() => {
        if (user && loginAbility) {
            fetchUserRelations();
            setProfile({
                email: user.email,
                name: user.name,
                tutorMode: false,
            });
        }
    }, [user, loginAbility]);

    const selectStudentProfile = (studentEmail, studentName) => {
        const tutorMode = studentEmail !== user.email;

        setProfile({
            email: studentEmail,
            name: studentName,
            tutorMode: tutorMode,
        });
    };

    return (
        <UserProfileContext.Provider
            value={{
                profile,
                studentDict,
                setStudentDict,
                selectStudentProfile,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    );
};

export const useUserProfile = () => useContext(UserProfileContext);
