import React, { useEffect, useState } from "react";

const Clock = ({ clockTime, setClockTime }) => {
    const [showTime, setShowTime] = useState(true); // State to toggle time visibility

    useEffect(() => {
        const timer = setInterval(() => {
            if (clockTime > 0) {
                setClockTime(clockTime - 1); // Decrement clockTime every second
            } else {
                // Stop the timer and show the popup when clockTime reaches 0
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [clockTime, setClockTime]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const toggleTimeVisibility = () => {
        setShowTime(!showTime);
    };

    return (
        <div>
            <button onClick={toggleTimeVisibility} className="header-button">
                {showTime ? "Hide Time" : "Show Time"}
            </button>
            {showTime && clockTime != null && (
                <div>{formatTime(clockTime)}</div>
            )}
        </div>
    );
};

export default Clock;
