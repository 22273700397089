function areObjectsEqual(obj1, obj2) {
    // Check if both arguments are objects
    if (
        typeof obj1 !== "object" ||
        typeof obj2 !== "object" ||
        obj1 === null ||
        obj2 === null
    ) {
        return false;
    }

    // Get the keys of both objects, filtering out keys with null values
    let keys1 = Object.keys(obj1).filter((key) => obj1[key] !== null);
    let keys2 = Object.keys(obj2).filter((key) => obj2[key] !== null);

    // If the number of keys is different, the objects are not equal
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys and values are equal
    for (let key of keys1) {
        // Check if the key exists in the second object
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            return false;
        }

        // Check if the values of the key are objects, and if so, recursively compare them
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
            if (!areObjectsEqual(obj1[key], obj2[key])) {
                return false;
            }
        } else {
            // Check if the values of the key are equal
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    }

    return true;
}

export default areObjectsEqual;
