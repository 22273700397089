import React, { useMemo, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from "./components/home/HomePage";
import ProblemPage from "./components/problem_console/ProblemPage";
import Practice from "./components/practice/Practice";
import { UserProfileProvider } from "./components/auth/UserProfileContext"; // adjust the path as necessary
import { JourneyProvider } from "./components/common/JourneyContext";
import { SettingsProvider } from "./components/common/SettingsContext";
import { SocketProvider } from "./components/common/SocketContext";

import Settings from "./components/settings/Settings";
import ErrorPage from "./components/common/ErrorPage";
import { updateIntercomVisibility } from "./components/common/intercom/IntercomUtils";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { ErrorProvider } from "./components/common/ErrorContext";
import { LoginProvider } from "./components/auth/LoginContext";
import AdminPage from "./components/admin/AdminPage";
import { CheckoutForm, CheckoutReturn } from "./components/stripe/Checkout";
import { useLoginContext } from "./components/auth/LoginContext";
import { useMetaPixel } from "./components/meta/MetaPixel"; // Adjust the import path as needed

function AppRoutes() {
    const { isAuthenticated } = useAuth0();
    const location = useLocation();
    const { loginAbility, loginLoading } = useLoginContext();

    const practiceRoutesConfig = [
        { path: "/practice/tests", stepType: "tests" },
        { path: "/practice/modules", stepType: "modules" },
        { path: "/practice/problem-sets", stepType: "problem-sets" },
    ];

    const intercomEnabledPaths = useMemo(() => {
        const practicePaths = practiceRoutesConfig.map((route) => route.path);
        return ["/", "/settings", ...practicePaths];
    }, []); /// this will only be computed once

    useEffect(() => {
        const shouldShowIntercom =
            intercomEnabledPaths.includes(location.pathname) && isAuthenticated;
        updateIntercomVisibility(shouldShowIntercom);
    }, [location, intercomEnabledPaths, isAuthenticated]);

    if (loginLoading || loginAbility === null) {
        return <div>Loading...</div>;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    loginAbility === false ? (
                        <Navigate to="/checkout" />
                    ) : (
                        <HomePage />
                    )
                }
            />
            {practiceRoutesConfig.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={<Practice stepType={route.stepType} />}
                />
            ))}
            <Route
                path="/practice/:stepType/:testId"
                element={<ProblemPage />}
            />
            <Route path="/settings" element={<Settings />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/checkout" element={<CheckoutForm />} />
        </Routes>
    );
}

function App() {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    const location = useLocation();
    useMetaPixel();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            if (location.pathname === "/signup") {
                const signupUrl = new URL(
                    "https://auth0.platform.aliaeducation.com/authorize",
                );
                signupUrl.searchParams.append("response_type", "code");
                signupUrl.searchParams.append(
                    "client_id",
                    "TQn5yos8s6SeNwFtOydPoz5PS4V21oHW",
                );
                signupUrl.searchParams.append(
                    "redirect_uri",
                    `${window.location.origin}/`,
                );
                signupUrl.searchParams.append("screen_hint", "signup");
                signupUrl.searchParams.append("state", Date.now().toString());

                window.location.href = signupUrl.toString();
            } else {
                loginWithRedirect();
            }
        }
    }, [isAuthenticated, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <ErrorProvider>
            <ErrorBoundary>
                <LoginProvider>
                    <UserProfileProvider>
                        <SettingsProvider>
                            <SocketProvider>
                                <JourneyProvider>
                                    <CheckoutReturn />
                                    <AppRoutes />
                                </JourneyProvider>
                            </SocketProvider>
                        </SettingsProvider>
                    </UserProfileProvider>
                </LoginProvider>
            </ErrorBoundary>
        </ErrorProvider>
    );
}

export default App;
