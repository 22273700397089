import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

export const useMetaPixel = () => {
    useEffect(() => {
        // Create a new script element
        const script = document.createElement("script");
        script.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        `;
        document.head.appendChild(script);

        // Check if the script loaded successfully
        const checkScriptLoaded = () => {
            if (typeof window.fbq === "function") {
                ReactPixel.init("2158648481172357");
                ReactPixel.pageView();
                console.log("Facebook Pixel loaded successfully");
            } else {
                console.warn(
                    "Facebook Pixel failed to load. It might be blocked by an ad-blocker.",
                );
                // Here you could trigger a user notification about the blocked script
            }
        };

        // Set a timeout to check if the script loaded
        const timeoutId = setTimeout(checkScriptLoaded, 2000); // Check after 2 seconds

        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
            document.head.removeChild(script);
        };
    }, []);
};

export const trackStripeConversion = (userEmail) => {
    const eventId = `purchase-${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;

    try {
        ReactPixel.track(
            "Purchase",
            {
                email: userEmail,
            },
            {
                eventID: eventId,
            },
        );
    } catch (error) {
        console.warn("Error tracking Facebook Pixel event:", error);
    }

    return eventId;
};
